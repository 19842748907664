<template>
  <div class="container">
    <BasicTitle title="Nuevo Contrato" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Usuario Personal *" />
      <BorderSelect
        v-model="license.data.personnelId"
        label="Usuario Personal *"
        :options="store.state.personnel.personnelList"
      />
      <FormError
        :show="license.rules.personnelId"
        message="Seleccione un usuario personal"
      />
      <BasicLabel label="Tipo de Licencia *" />
      <BorderSelect
        v-model="license.data.licenseTypeId"
        label="Tipo de Licencia *"
        :options="store.state.license.licenseType"
      />
      <FormError
        :show="license.rules.licenseTypeId"
        message="Seleccione un tipo de licencia"
      />
      <BasicLabel label="Fecha de Inicio *" />
      <BorderDate v-model="license.data.fromDate" label="Fecha de Inicio *" />
      <FormError
        :show="license.rules.fromDate"
        message="Seleccione una fecha"
      />
      <BasicLabel label="Fecha de Fin *" />
      <BorderDate v-model="license.data.toDate" label="Fecha de Fin *" />
      <FormError :show="license.rules.toDate" message="Seleccione una fecha" />
      <BasicLabel label="Cantidad de días *" />
      <BorderInput
        v-model="license.data.days"
        type="number"
        label="Cantidad de días *"
      />
      <FormError
        :show="license.rules.days"
        message="Ingrese la cantidad de días"
      />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    BorderTime,
    FormError,
    PrimaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const license = reactive({
      selectedDocuments: [],
      data: {
        id: -1,
        personnelId: "",
        licenseTypeId: "",
        fromDate: "",
        toDate: "",
        days: "",
      },
      rules: {
        personnelId: false,
        licenseTypeId: false,
        fromDate: false,
        toDate: false,
        days: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!license.data.personnelId) {
        license.rules.personnelId = true;
        valid = false;
      }
      if (!license.data.licenseTypeId) {
        license.rules.licenseTypeId = true;
        valid = false;
      }
      if (!license.data.fromDate) {
        license.rules.fromDate = true;
        valid = false;
      }
      if (!license.data.toDate) {
        license.rules.toDate = true;
        valid = false;
      }
      if (!license.data.days) {
        license.rules.days = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        buildingId: store.state.general.selectedBuilding,
        personnelId: license.data.personnelId,
        licenseTypeId: license.data.licenseTypeId,
        fromDate: license.data.fromDate,
        toDate: license.data.toDate,
        days: license.data.days,
      };

      const response = await store.dispatch(
        actions.licenseActions.create,
        request
      );

      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedLicenses", []);
        router.push("/licencias/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/licencias/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch(
        actions.personnelActions.findAll,
        store.state.general.selectedBuilding
      );
      await store.dispatch(
        actions.licenseActions.type,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (license.data.personnelId) license.rules.personnelId = false;
      if (license.data.licenseTypeId) license.rules.licenseTypeId = false;
      if (license.data.fromDate) license.rules.fromDate = false;
      if (license.data.toDate) license.rules.toDate = false;
      if (license.data.days) license.rules.days = false;
    });

    return { store, license, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
